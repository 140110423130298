* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  #root {
    overflow: hidden;
    position: relative;
    min-height: 100vh;
  }
  
  a {
    text-decoration: none;
  }
  
  a:visited {
    text-decoration: none;
  }
  
  body {
    margin: 0;
    font-family: Lato, -apple-system, BlinkMacSystemFont, LithosPro-Regular, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Lithos Pro",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  