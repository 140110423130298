div.DraftEditor-root {
    background-color: #fffef7;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.5);
    overflow-y: scroll;
    padding: 20px;
    font-size: 18px;
    font-family: 'calibri', sans-serif;
    max-width: 90%;
    width: 600px;
    margin-left: auto;
    margin-right: 0%;
    margin-bottom: 5%;
    flex-shrink: 0;
    height: 300px;
    max-height: 90%;
    z-index: 10;
  }

  @media screen and (max-width: 55em){
    div.DraftEditor-root {
      margin: auto;
      height: 200px;
    }
  }